@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: local('PT Sans'),
         local('PTSans-Regular'),
         url('../fonts/PT_Sans-Web-Regular.woff2') format('woff2'),
         url('../fonts/PT_Sans-Web-Regular.woff') format('woff'),
         url('../fonts/PT_Sans-Web-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    src: local('PT Sans Bold'),
         local('PTSans-Bold'),
         url('../fonts/PT_Sans-Web-Bold.woff2') format('woff2'),
         url('../fonts/PT_Sans-Web-Bold.woff') format('woff'),
         url('../fonts/PT_Sans-Web-Bold.ttf') format('truetype');
}
