@import '~react-image-gallery/styles/css/image-gallery.css';
@import './fonts/ibsh-spendenplattform/css/style.css';
@import './fonts/PT_Sans/css/style.css';
@import '~bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration-line: underline;
  font-weight: bold;
  color: #064a78 !important;
}

.btn-primary {
  background: #D4004B !important;
  border-radius: 3px !important;
  border: 0 !important;

  font-weight: bold !important;
}

.btn-default {
  background-color: #003064 !important;
  color: #fff !important;
  border-radius: 3px !important;
  border: 0 !important;

  font-weight: bold !important;
  margin-bottom: 1em;
}

.form-control {
  border-color: #cecece !important;
  border-radius: 0 !important;
}

label {
  font-weight: bold;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  text-transform: uppercase;
  color: #003064;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 60px;
  text-transform: uppercase;
  color: #003064;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 60px;
  color: #003064;
}

h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  color: #003064;
}

h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 60px;
  color: #003064;
}

h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #003064;
}

h7 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 60px;
  color: #003064;
}

.container-lg {
  max-width: 960px !important;
  padding-left: 0;
  padding-right: 0;
}

.mytooltip > .tooltip-inner {
  max-width: 100%;
}

.project-popup .leaflet-popup-content-wrapper {
  border-radius: 0px;
  padding: 0;
}

.project-popup .leaflet-popup-content {
  margin: 0;
}

@media screen and (max-width: 992px) {
  .container-lg {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.cookie-banner {
  bottom: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  display: flex;
  color: #003064;
  background-color: #e0e0ed;
  padding: 1em 1.8em;
  font-family: PT Sans, sans-serif;
  font-size: 16px !important;
  line-height: 1.3em !important;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.cb-content {
  display: flex;
  max-width: 100%;
  margin-right: 1em;
  flex: 0 0 626px;
}

.cb-buttons-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
}

.cb-btn {
  width: 80%;
}

.cb-btn:hover {
  width: 80%;
  background-color: #aa003c !important;
  border-color: #aa003c !important;
}

@media screen and (max-width: 992px) {
  .cookie-banner {
    flex-direction: column;
  }

  .cb-content {
    margin-bottom: 1em;
    margin-right: 0;
    flex: 1 1 auto;
  }
}
