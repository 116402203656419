@font-face {
    font-family: 'ibsh-spendenplattform';
    src:url('../fonts/ibsh-spendenplattform.eot?cymc01');
    src:url('../fonts/ibsh-spendenplattform.eot?#iefixcymc01') format('embedded-opentype'),
        url('../fonts/ibsh-spendenplattform.woff?cymc01') format('woff'),
        url('../fonts/ibsh-spendenplattform.ttf?cymc01') format('truetype'),
        url('../fonts/ibsh-spendenplattform.svg?cymc01#ibsh-spendenplattform') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'ibsh-spendenplattform';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-share:before {
    content: "\e61c";
}
.icon-foto:before {
    content: "\e61b";
}
.icon-bearbeiten:before {
    content: "\e619";
}
.icon-spendenquittung:before {
    content: "\e61a";
}
.icon-check:before {
    content: "\e616";
}
.icon-info:before {
    content: "\e617";
}
.icon-trash:before {
    content: "\e618";
}
.icon-akkordeon-minus:before {
    content: "\e600";
}
.icon-akkordeon-plus:before {
    content: "\e601";
}
.icon-bullet:before {
    content: "\e602";
}
.icon-clock:before {
    content: "\e603";
}
.icon-close:before {
    content: "\e604";
}
.icon-grid:before {
    content: "\e605";
}
.icon-hamburger:before {
    content: "\e606";
}
.icon-link-answer:before {
    content: "\e607";
}
.icon-link-back:before {
    content: "\e608";
}
.icon-link-download:before {
    content: "\e609";
}
.icon-link-extern:before {
    content: "\e60a";
}
.icon-link-intern:before {
    content: "\e60b";
}
.icon-link-mail:before {
    content: "\e60c";
}
.icon-link-phone:before {
    content: "\e60d";
}
.icon-link-watch:before {
    content: "\e60e";
}
.icon-map:before {
    content: "\e60f";
}
.icon-megaphone:before {
    content: "\e610";
}
.icon-search:before {
    content: "\e611";
}
.icon-support:before {
    content: "\e612";
}
.icon-supporter:before {
    content: "\e613";
}
.icon-talk:before {
    content: "\e614";
}
.icon-thumb:before {
    content: "\e615";
}
